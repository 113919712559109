$gray900: #111928;
$uhOrange: #f49130;
$breakPoint: 767px;

//body:has does not work on safari
//body:has(#cookies-policy.cookies.cookies--show),
//.cookie-disable-body-scroll {
//    @media only screen and (max-width: $breakPoint) {
//        overflow-y: hidden;
//    }
//}

body:has(#cookies-policy.cookies.cp-visible),
.cookie-disable-body-scroll {
    overflow-y: hidden;
}

.cp-hidden {
    display: none;
}

#cookies-policy.cookies {
    font-size: 16px;
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: auto;
    z-index: 9999;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
    top: 0;
    left: 0;
    background: rgba(17, 25, 40, 0.65);

    &--closing {
        opacity: 0;
        transform: translateY(10px);
    }
    &--show {
        .cookies {
            &__alert {
                @media only screen and (max-width: $breakPoint) {
                    position: relative;
                    border-radius: unset;
                    border: unset;
                    padding-bottom: 152.5px;
                }
            }
        }
        .cookies__btn {
            &--customize {
                border-top: none;
                border-bottom: 1px solid #e5e7eb;
                padding-top: 0;
                padding-bottom: 1em;
            }

            svg {
                transform: rotate(180deg);
            }
        }
    }

    &--no-js {
        .cookies__section .cookies__expandable,
        .cookies__expandable:target {
            height: auto;
            visibility: visible;
            opacity: 1;
            transition: height 300ms ease-out, opacity 300ms ease-out;
        }

        .cookies__details {
            display: none;
        }

        .cookies__sections {
            max-height: fit-content;
        }

        svg {
            display: none;
        }
    }
}
#cookies-policy .cookies {
    &__expandable {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: height 200ms ease-out, opacity 200ms ease-out,
            visibility 0s 200ms linear;

        &--open {
            height: auto;
            visibility: visible;
            opacity: 1;
            transition: height 200ms ease-out, opacity 200ms ease-out;

            form {
                overflow: scroll;
            }
        }
    }

    &__alert {
        display: flex;
        flex-direction: column;
        width: 33em;
        max-width: 90%;
        max-height: 90%;
        margin: 4%;
        padding: 1.5em;
        background: #fff;
        border-radius: 16px;
        border: 1px solid #00a2e1;
        -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05),
            0px 10px 15px -3px rgba(0, 0, 0, 0.1);

        position: fixed;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: $breakPoint) {
            max-width: 100%;
            max-height: 100%;
            //max-height: calc(var(--vh, 1vh) * 100) !important;
            width: 100%;
            margin: unset;
            border-radius: 8px 8px 0px 0px;
            padding: 1.5em 1em;
            border-top: 2px solid #00a2e1;
        }

        @media only screen and (min-width: $breakPoint) {
            margin: 1.55%;
        }
    }

    &__container {
        display: block;
        overflow: hidden;
        height: auto;
        visibility: visible;
        opacity: 1;
        transition: height 200ms ease-out, opacity 200ms ease-out,
            visibility 0s 100ms linear;

        &--hide {
            height: 0;
            visibility: hidden;
            opacity: 0;
            transition: height 200ms ease-out, opacity 200ms ease-out,
                visibility 0s 200ms linear;
        }
    }

    &__wrapper {
        padding-bottom: 1em;
    }

    &__title {
        font-weight: bold;
        line-height: normal;
        font-style: normal;
        margin-bottom: 0.5em;
        font-size: 1em;
        color: $gray900;
    }

    &__intro,
    &__sections--footer {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 1.313em;
        font-style: normal;
        color: $gray900;

        p {
            margin-top: 1.5em;

            &:first-child {
                margin-top: 0;
            }
        }

        a {
            text-decoration: underline;
            color: inherit;
            transition: color 200ms ease-out;

            &:hover,
            &:focus {
                color: $uhOrange;
            }
        }
    }

    &__sections--footer {
        padding: 1.5em 0;

        p {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__actions {
        margin-top: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;
    }

    &__btn {
        &--customize {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 1em;
            line-height: normal;
            text-decoration: none;
            color: $gray900;
            position: relative;
            padding-top: 1.125em;
            border-top: 1px solid #e5e7eb;
        }

        svg {
            transition: transform 200ms ease-out;
        }
    }

    &__sections {
        max-height: 450px;
        overflow-y: auto;

        &--intro,
        &--bullet_points {
            color: $gray900;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.313em;
            padding-top: 1.5em;
        }

        &--bullet_points {
            p {
                margin-bottom: 0;
            }

            ul {
                list-style-type: disc;
                list-style-position: outside;
                margin-bottom: 0;
            }
        }
    }

    &__section {
        padding-top: 1.5em;

        //&:last-child {
        //    padding: 1.5em;
        //}
    }

    //&__section + .cookies__section {
    //    border-top: 1px solid #eee;
    //}

    &__category,
    &__box {
        display: block;
        position: relative;
        overflow: hidden;
    }

    &__category {
        border: 1px solid #e5e7eb;
        border-radius: 16px;
        padding: 0.5em 1em 1em 1em;

        input {
            position: absolute;
            display: block;
            top: 0;
            right: 105%;
            padding: 0;
            margin: 0;
        }
    }

    &__box {
        padding: 0.5em 3em 0.5em 0;
        line-height: 1.4em;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            border-radius: 1.4em;
        }
        &:after {
            right: 0;
            width: 2.5em;
            height: 1.4em;
            margin-top: -0.7em;
            background: #e7e8e9;
            z-index: 0;
            transition: background 200ms ease-out, opacity 200ms ease-out;
        }
        &:before {
            right: 0.75em;
            width: 1em;
            height: 1em;
            margin-top: -0.5em;
            background: #fff;
            z-index: 1;
            transform: translateX(-0.55em);
            transition: transform 200ms ease-out;
        }
    }

    &__category:has(input:checked) {
        border: 1px solid #0e9f6e;
    }

    &__category input:checked + .cookies__box:after {
        background: #0e9f6e;
        opacity: 1;
    }

    &__category input:disabled + .cookies__box:after {
        opacity: 0.6;
    }

    &__category input:checked + .cookies__box:before {
        transform: translateX(0.55em);
    }

    &__label {
        font-size: 0.875em;
        font-weight: 600;
        color: #2c2e30;
    }

    &__info {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 1.313em;
        font-style: normal;
        color: #6b7280;
    }

    &__details {
        display: block;
        color: $uhOrange;
        font-size: 0.875em;
        margin: 0.625em 0 0.9em;
        transition: color 200ms ease-out;

        &:hover,
        &:focus {
            color: $uhOrange;
        }
    }

    &__definitions {
        font-size: 0.875em;
        line-height: 1.2em;
        padding-top: 1em;
        padding-top: 0.8em;
        color: #676767;
    }

    &__cookie + .cookies__cookie {
        margin-top: 1em;
    }

    &__name {
        display: inline;
        font-weight: 600;
        line-height: 22px;
        color: #8a8e92;
    }

    &__duration {
        display: inline;
        text-align: right;
        color: #8a8e92;
    }

    &__description {
        padding-top: 0.3em;
        display: block;
        width: 100%;
        line-height: 120%;
        text-align: left;
        color: #8a8e92;
    }

    &__save {
        border-top: 1px solid #e5e7eb;
        padding-top: 1em;
        display: flex;
        justify-content: flex-end;
        gap: 16px;

        @media only screen and (max-width: $breakPoint) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1.5em 1em;
            flex-direction: column-reverse;
            background-color: #fff;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
        }
    }
}

#cookies-policy .cookiesBtn {
    width: 100%;

    &__link {
        display: block;
        font: inherit;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        background: $uhOrange;
        border: 1px solid $uhOrange;
        border-radius: 8px;
        margin: 0;
        padding: 0.625em 1.25em;
        line-height: 1em;
        text-align: center;
        text-decoration: none;
        height: 3.125em;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: opacity 200ms ease-out;

        &:hover,
        &:focus {
            opacity: 0.8;
        }

        &.outline {
            border: 1px solid #6b7280;
            background-color: #fff;
            color: #6b7280;
        }
    }
}

#cookies-policy .cookies__wrapper {
    position: relative;
}

#cookies-policy .cookiesBtnClose {
    position: absolute;
    right:0;
    top:0;
    width:20px;

    .cookiesBtn__link {
        background: transparent;
        border:none;
        height: 20px;
        padding:0;
        position: relative;
        width: 20px;

        .cookiesBtn__label {
            height: 18px;
            opacity: 0.5;
            position: absolute;
            right: 0;
            top: 0;
            width: 18px;

            &:hover {
                opacity: 1;
            }

            &:before, &:after {
                background-color: #333;
                content: ' ';
                height: 18px;
                left: 5px;
                position: absolute;
                width: 4px;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }
}
